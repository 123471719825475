<template>
	<div>
		<v-card>
			
			<v-container>
				<v-row>
					<v-col>
						<s-toolbar
							label="Configuración de Parámetros"
							color="primary"
							dark
						></s-toolbar>
					</v-col>
				</v-row>
				<v-data-table
					:headers="headers"
					:items="parametersList"
				>
					<template v-slot:item.action ="{ item }">
						<div>
							<v-icon
								small
								class="mr-2"
								@click="editParameter(item)">
								mdi-pencil
							</v-icon>
						</div>
					</template>
				</v-data-table>
			</v-container>
		</v-card>

		<v-dialog
			v-model="dialogParameter"
			max-width="400px"
			height="80px"
			persistent
		>
			<v-card>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col>
								<s-text
									v-model="PrmValue"
									label="Valor">
								</s-text>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>

				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="warning"
					text
					@click="closeDialogEditParameter()"
				>
					Cancelar
				</v-btn>
				<v-btn
					color="primary"
					text
					@click="save()"
				>
					Guardar
				</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import _sParameterService from "@/services/General/ParameterService";

	export default {
		data() {
			return {
				headers: [
					{text:"Parámetro", value: "PrmName"},
					{text:"Valor", value: "PrmValue"},
					{text:"Acción", value: "action"},
				],
				parameters: [
					'CongeladoBrixMaximo',
					'CongeladoBrixMinimo',
					'CongeladoColorMaximo',
					'CongeladoColorMinimo',
					'CongeladoFirmezaMaxima',
					'CongeladoFirmezaMinima',
					'CongeladoPhMaximo',
					'CongeladoPhMinimo',
					'CongeladoSaborMaximo',
					'CongeladoSaborMinimo'
				],
				parametersString: "",
				parametersList: [],
				dialogParameter: false,
				PrmValue: '',
				parameterSelect: {}
			}
		},

		methods: {
			getParamaters() {
				this.parametersString = this.parameters.join(", ");

				_sParameterService
				.searchlist(this.parametersString, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.parametersList = resp.data;
						console.log(this.parametersList);
					}
				});
			},

			save()
			{
				this.$fun.alert("¿Seguro de guardar?", "question")
				.then(r => {
					if(r.value)
					{
						this.parameterSelect.PrmValue = this.PrmValue;
						_sParameterService
						.save(this.parameterSelect, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Guardado Correctamente", "success");
								this.getParamaters();
								this.closeDialogEditParameter();
							}
						})
					}
				})
			},

			editParameter(item)
			{
				this.PrmValue = item.PrmValue;
				this.parameterSelect = item;
				this.dialogParameter = true;
				
			},

			closeDialogEditParameter()
			{
				this.dialogParameter = false;
			}
		},

		created () {
			this.getParamaters();
		},
	}
</script>